import { useEffect, useState } from "react";
import { getRankedStats } from "../../api/ranked-stats";
import { ALL_ID, CurrentEvent, Event, RankedStatsId } from "../../api/ranked-stats.types";
import { RankedsTable } from "./rankeds-table/rankeds-table";
import { RankedsMaps } from "./rankeds-maps/rankeds-maps";
import { MainContainer, MainContainerMb, MainTitle, MapsContainer, MapsContainerMb, PageContainer, TableContainer } from "./rankeds.styles";
import { Box, useMediaQuery } from "@mui/material";
import { Footer } from "../../components/footer/footer";
import { screenBreackpoints } from "../../theme";

export const Rankeds: React.FC = () => {
  const [eventId, setEventId] = useState<RankedStatsId>(ALL_ID);
  const [currentEvents, setCurrentEvents] = useState<CurrentEvent[]>([]);
  const [event, setEvent] = useState<Event>();
  const isMobile = useMediaQuery(`(max-width:${screenBreackpoints.mobile})`);

  useEffect(() => {
    const loadRankedStats = async () => {
      try {
        const rankedStats = await getRankedStats(eventId);
        setEvent(rankedStats.event);
        setCurrentEvents(rankedStats.currentEvents);
      } catch (error) {
        window.console.log(error)
      }
    };
    loadRankedStats()
  }, [eventId]);

  const handleNavigation = (eventId: RankedStatsId) => {
    setEventId(eventId);
    window.scrollTo({ top: 0, behavior: 'smooth', });
  };

  return (
    <PageContainer isMobile={isMobile}>
      <Box>
        <MainTitle component="h1" isMobile={isMobile}>
          Top Brawlers for Ranked Mode. <br />
          Brawl Stars Statistics of the Current Meta.
        </MainTitle>
        {isMobile ?
          <MainContainerMb>
            {event && (
              <RankedsTable event={event} />
            )}
            {currentEvents && (
              <MapsContainerMb>
                <RankedsMaps
                  selectedMapId={eventId}
                  currentEvents={currentEvents}
                  onClickMap={(eventId) => handleNavigation(eventId)} />
              </MapsContainerMb>
            )
            }
          </MainContainerMb>
          :
          <MainContainer>
            <TableContainer>
              {event && (
                <RankedsTable event={event} />
              )}
            </TableContainer>
            <MapsContainer>
              {currentEvents && (
                <RankedsMaps
                  selectedMapId={eventId}
                  currentEvents={currentEvents}
                  onClickMap={(eventId) => handleNavigation(eventId)} />
              )}
            </MapsContainer>
          </MainContainer>
        }
      </Box>
      <Footer />
    </PageContainer>
  );
}
