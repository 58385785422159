import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { componentColors, fontSize } from '../../theme';

interface PageContainerProps {
  isMobile?: boolean;
}

export const PageContainer = styled(Box) <PageContainerProps>`
  overflow-y: hidden;
  min-height: 100%;
  background-color: ${componentColors.pageBackground};
  color: ${componentColors.pageContrast};
  padding: ${({ isMobile }) => (isMobile ? "16px 8px 16px" : "24px")};
  font-size: ${({ isMobile }) => (isMobile ? `${fontSize.mobile}px` : `${fontSize.default}px`)};
`

interface MainTitleProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  isMobile?: boolean
  component?: string;
}

export const MainTitle = styled(Typography) <MainTitleProps>`
  font-size: ${({ isMobile }) => (isMobile ? "1.4em" : "1.8em")};
  padding-bottom: 16px;
`;

export const MainContainer = styled(Box)({
  display: "flex",
  width: "100%",
  paddingBottom: "24px"
});

export const TableContainer = styled(Box)({
  width: "30%",
  '@media (max-width: 1500px)': {
    width: "40%"
  },
  '@media (max-width: 1200px)': {
    width: "50%"
  }
});

export const MapsContainer = styled(Box)({
  paddingLeft: "32px",
  width: "70%",
  '@media (max-width: 1500px)': {
    width: "60%"
  },
  '@media (max-width: 1200px)': {
    width: "50%"
  }
});

export const MapsContainerMb = styled(Box)({
  paddingTop: "40px",
});

export const MainContainerMb = styled(Box)({
  display: "flex",
  width: "100%",
  flexDirection: "column"
});