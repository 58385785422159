import { BrawlerStats } from "../../../api/ranked-stats.types";
import { Order } from "./rankeds-table.types";

export const sortBrawlerStats = (
    stats: BrawlerStats[],
    property: keyof BrawlerStats,
    order: Order
): BrawlerStats[] => {
    return stats.slice().sort((a, b) => {
        const valueA = a[property];
        const valueB = b[property];

        if (valueA === null && valueB === null) return 0;
        if (valueA === null) return order === Order.Ascending ? -1 : 1;
        if (valueB === null) return order === Order.Ascending ? 1 : -1;

        if (typeof valueA === 'number' && typeof valueB === 'number') {
            return order === Order.Ascending ? valueA - valueB : valueB - valueA;
        }

        if (typeof valueA === 'string' && typeof valueB === 'string') {
            return order === Order.Ascending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        }

        return 0;
    });
};

export const formatPercentaje = (num: number | null) => num !== null ? (num * 100).toFixed(2) : "?"

export const formatScore = (num: number | null) => num !== null ? (num * 20).toFixed(2) : "?"

export const getImageSrc = (brawlerName: string) =>
    `/images/brawlers-icon/${brawlerName.toLowerCase().replace(/[\s.,]/g, '_').replace(/[&]/g, '_')}.webp`

